import "../Clicker.css";
import { useState, useEffect } from "react";
import { BsTrash, BsBookmark, BsBookmarkCheckFill, BsPencilSquare } from "react-icons/bs";
import { formatDateAsHumanFriendly, getTodayDateAsString } from "../DateHelper";
import { Room } from "../types/room";
import React from 'react';
import { Link } from "react-router-dom";
import Loading from "./Loading";
import { createRoom, deleteRoom, editRoom, getAllRooms } from "../ServerClient";
import { NavBar } from "./NavBar";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { useGetToken } from "../GetToken";


function RoomsList() {
    const [rooms, setRooms] = useState<Room[]>([])
    const [loading, setLoading] = useState(false)
    const [showAllRooms, setShowAllRooms] = useState(true)
    const token = useGetToken()

    useEffect(() => {
        if(!!token){
            loadData()
        }
    }, [token])

    const loadData = async() => {
        setLoading(true)
        const rooms = await getAllRooms(token!)

        setLoading(false)
        setRooms(rooms)
    }

    if (loading) {
        return <Loading/>
    }

    const isAdmin = window.location.pathname === "/admin"
    const filteredRooms = rooms.filter(room => {
        if (isAdmin) { //Admins can pick what to see
            if(showAllRooms){
                return true
            } else {
                return room.Done === false
            }
        }
        //Users can only see rooms that are *not* marked as Done
        return !room.Done
    })

    return (
        <>
            <NavBar/>
            <AuthenticatedTemplate>

                <div className='RoomsList'>
                    <div className="RoomsList-header">
                        <h1>Klicker</h1>
                    </div>
                    {isAdmin ?
                        <NewRoomForm loadData={loadData}/>
                        :
                        <></>
                    }
                    {isAdmin ? <HideDoneRoomsSelector showAllRooms={showAllRooms} setShowAllRooms={(newState) => setShowAllRooms(newState)}/> : <></>}
                    <div className="list-Rooms">
                        {filteredRooms.length === 0 && <p>Inga händelser!</p>}
                        {filteredRooms.slice().sort((a, b) => {
                            const aTime = new Date(a.EventDate).getDate();
                            const bTime = new Date(b.EventDate).getDate();
                            return aTime - bTime;

                        }).map((room) => (
                            <div className="room" key={room.Id}>
                                <h3 className={room.Done ? "room-done" : ""}>
                                    <Link style={{ textDecoration: 'none' }} to={"/clicker/"+room.Id}>{room.Name}</Link>
                                </h3>
                                <h4>{formatDateAsHumanFriendly(room.EventDate)}</h4>
                                {isAdmin ?
                                <EditRoomActions loadData={loadData} room={room}/>
                                :
                                <></> }
                            </div>
                        ))}
                    </div>
                </div>
            </AuthenticatedTemplate>
        </>
    )
}

type NewRoomFormProps = {
    loadData: () => void
}

const NewRoomForm = ({loadData} : NewRoomFormProps) => {
    const [title, setTitle] = useState("")
    const [eventDate, setDate] = useState(getTodayDateAsString())
    const token = useGetToken()

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        await createRoom(title, eventDate, token!)
        setTitle("")
        setDate(getTodayDateAsString())
        loadData()
    }

    return (
        <div className="CreateRoom-form">
            <h2>Ny händelse:</h2>
            <form onSubmit={handleSubmit}>

                <div className="input-display">
                    <div className='roomsTitle'>Rubrik:</div>
                    <input type="text" name="title" placeholder="Skriv här!" onChange={(e) => setTitle(e.target.value)} value={title} required/>
                </div>

                <div className="input-display">
                    <div className='roomsTitle'>Datum</div>
                    <input type="date" name="time" onChange={(e) => setDate(e.target.value)} value={eventDate} required/>
                </div>

                <input className = 'createClicker' type="submit" value="Skapa Klicker" />
            </form>
        </div>
    )
}

type EditRoomProps = {
    loadData: () => void,
    room: Room
}
const EditRoomActions = ({loadData, room} : EditRoomProps) => {
    const token = useGetToken()

    const flipDoneCheck = async(room:Room) => {
        var updatedRoom = {...room, Done: !room.Done}
        await editRoom(updatedRoom, token!)
        loadData()
    }

    const handleDelete = async (id:string) => {
        await deleteRoom(id, token!)
        loadData()
    }

    const setNewName = async(room:Room, newName:string) => {
        var updatedRoom = {...room, Name: newName}
        await editRoom(updatedRoom, token!)
        loadData()
    }

    return (
    <div className="actions" >
        <span onClick={() => flipDoneCheck(room)}>
            {!room.Done ? <BsBookmark /> : <BsBookmarkCheckFill/>}
        </span>
        <BsTrash onClick={() => {
            const confirmBox = window.confirm(
                "Är du säker att du vill ta bort det här rummet?"
            )
            if(confirmBox === true){
                handleDelete(room.Id)}
            }
        }/>
        <BsPencilSquare onClick = {() => {
                const enteredName = prompt("Skriv den nya rubriken!", (room.Name))

                if(enteredName != null){
                    setNewName(room, enteredName)
                }
            }
        } />
    </div>
    )
}

type HideDoneRoomsSelectorProps = {
    showAllRooms: boolean,
    setShowAllRooms: (newState: boolean) => void
}

const HideDoneRoomsSelector = ({showAllRooms, setShowAllRooms} : HideDoneRoomsSelectorProps) => {

    return (
        <div className="HideDoneRoomsSelector">
            <input type="checkbox" id="hideDoneRooms" name="hideDoneRooms" checked={showAllRooms} onChange={(e) => setShowAllRooms(e.target.checked)}/>
            <label htmlFor="hideDoneRooms">Visa även gamla händelser</label>
        </div>
    )
}


export default RoomsList