import React from "react";
import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { BsPersonX } from "react-icons/bs";

function handleLogout(instance: IPublicClientApplication) {
    instance.logoutRedirect().catch((e: any) => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
export const SignOutButton = () => {
    const { instance } = useMsal();

    return (
        <div className="menuBarButton" onClick={() => handleLogout(instance)}><BsPersonX/></div>
    );
}