export const getTodayDateAsString = () => {
  return new Date().toLocaleString('sv-SE').substring(0,10)
}

/**
 * Will format the date as "onsdag 9 november"
 * @param date
 * @returns
 */
export const formatDateAsHumanFriendly = (date: string) => {
  return new Date(date).toLocaleString('sv-SE', { month: 'long', day: 'numeric', weekday: 'long' })
}