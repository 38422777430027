import React from "react";
import { UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import {  BsStopwatch } from 'react-icons/bs';
import {Link} from 'react-router-dom'

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const NavBar = () => {
    const isAuthenticated = useIsAuthenticated();

    return (
        <div className='menuBar'>
            <div className="grid-container">
                <div><Link to="/"><img className="gl_logo" src="/gl_logo.svg" alt="logo"/></Link></div>
                <div className="sign-in-out-button">{ isAuthenticated ? <SignOutButton /> : <SignInButton /> }</div>
            </div>
            <UnauthenticatedTemplate>
              <p className= 'SigninText'>Du är inte inloggad. Vänligen logga in</p>
            </UnauthenticatedTemplate>
        </div>
    );
};