
import React, {useEffect, useState} from 'react';
import '../Clicker.css';
import {roomMetaDataById, sendClickToServer, subscribeToMessage } from '../ServerClient';
import {useParams} from 'react-router-dom';
import Loading from './Loading';
import { NavBar } from './NavBar';
import { AuthenticatedTemplate } from "@azure/msal-react";
import { useGetToken } from '../GetToken';
import { RoomWithClicks } from '../types/metaData';
import { formatDateAsHumanFriendly } from '../DateHelper';

function Clicker() {
  const [localCount, setLocalCount] = useState(0);
  const [globalValue, setGlobalValue] = useState(0);
  const params = useParams();
  const [room, setRoom] = useState<RoomWithClicks | null>(null);
  const [loading, setLoading] = useState(false);

  const token = useGetToken()

  useEffect(() => {
    subscribeToMessage('NewGlobalValue'+params.id, (message) => {
      setGlobalValue(message);
    });

  }, [params])

  useEffect(()=> {
    if (!!params.id && token) {
      findMetaDataById(params.id, token)
    }
  }, [params, token])

  useEffect(() => {
    if (!!room?.Id) {
      const localValue = Number(localStorage.getItem(room.Id));
      if ( localValue > 0){
        setLocalCount(localValue);
      }
    }
  }, [room])

  const findMetaDataById = async(roomId: string, token: string) =>{
    setLoading(true)
    const roomWithMetaData = await roomMetaDataById(roomId, token!)
    if(roomWithMetaData !== null){
      setRoom(roomWithMetaData)
      setGlobalValue(roomWithMetaData.TotalClicks)
    }
    setLoading(false)

  }

  const handleClick = async () => {
    const newlocalCount = localCount + 1;
    if (!!room?.Id){
      setLocalCount(newlocalCount)
      localStorage.setItem(room.Id, newlocalCount.toString());
      sendClickToServer(room?.Id)
      window.navigator.vibrate(200);
    }
  }

  if (loading) {
    return <Loading/>
  }

  return (
    <div className='outerWrapper'>
      <NavBar/>
      <AuthenticatedTemplate>
          <div className="Clicker">
            {!room && <p>Kunde inte hämta rum</p>}

            {room &&
            <>
                <div className="RoomsList-header">
                  <h1> {room.Name}</h1>
                  <h2>{formatDateAsHumanFriendly(room.EventDate)} </h2>
                </div>
                <div className="CreateRoom-form">
                  <div className="grid-clicks-display">
                    <div className='amountClicks'>Totala klick:</div>
                    <div className='Counter' > {globalValue} </div>
                  </div>
                  {!room.Done &&
                  <>
                    <div className='grid-clicks-display'>
                    <div className='amountClicks'>Dina klick:</div>
                    <div className='Counter' > {localCount} </div>
                  </div>
                  <div className='buttondiv'>
                    <input data-testid='button' className='MyButton' type="submit" value="" onClick={() => handleClick()}/>
                  </div>
                  </>
                  }
                </div>
              </>
              }
          </div>
      </AuthenticatedTemplate>
    </div>
  );
}

export default Clicker;
