import * as React from "react";
import * as ReactDOM from "react-dom/client";
import "./index.css";
import RoomsList from "./components/RoomsList";
import Clicker from "./components/Clicker";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { getMsalConfig } from "./authConfig";

var msalConfig = getMsalConfig();

console.log("msalConfig", msalConfig);

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RoomsList />} />
          <Route path="/admin" element={<RoomsList />} />
          <Route path="/clicker/:id" element={<Clicker />} />
        </Routes>
      </BrowserRouter>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
