import { getTokenRequest } from "./authConfig";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";

export const useGetToken = () => {
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState<string | null>("");

  const isAuthenticated = useIsAuthenticated();

  const tokenRequest = getTokenRequest();

  useEffect(() => {
    const request = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.accessToken);
      })
      .catch((e) => {
        // If acquiring the token silently fails, use acquireTokenRedirect to request consent interactively.
        instance.acquireTokenRedirect(request);
      });
  }, [isAuthenticated, instance, accounts, tokenRequest]);

  return accessToken;
};
