import loading from "../Spinner-3.gif"

function Loading(){
    return(
        <div className="loader_container">
        <img className="loader" src={loading} alt="Laddar!"/>
        </div>
    )

}

export default Loading