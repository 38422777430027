import { HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { RoomWithClicks } from "./types/metaData";
import { Room } from "./types/room";
import { getServerAPIEndpoint } from "./serverConfig";

var API = getServerAPIEndpoint();

const connection = new HubConnectionBuilder().withUrl(API + "/chat").build();

const getOptions = (accessToken: string, method: string, body?: any) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: method,
    body: body,
    headers: headers,
  };
  return options;
};

export const checkRes = <T, R>(
  res: Response,
  fallbackValue: R
): Promise<T | R> => {
  if (res.ok) {
    return res.json();
  }
  return new Promise(function (resolve, _) {
    resolve(fallbackValue);
  });
};

export const subscribeToMessage = async (
  messageName: string,
  method: (...args: any[]) => void
) => {
  const singelton = await getInstance();
  singelton.on(messageName, method);
};

const getInstance = async () => {
  if (connection.state === HubConnectionState.Disconnected) {
    await start();
    return connection;
  }
  return connection;
};

const start = async () => {
  await connection.start();
};

export const getAllRooms = async (accessToken: string): Promise<Room[]> => {
  const rooms = await fetch(API + "/api/rooms/", getOptions(accessToken, "GET"))
    .then((res) => {
      return checkRes<Room[], []>(res, []);
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
  return rooms;
};

export const roomMetaDataById = async (
  roomId: string,
  accessToken: string
): Promise<RoomWithClicks | null> => {
  const metadata = await fetch(
    API + "/api/rooms/" + roomId + "/metadata",
    getOptions(accessToken, "GET")
  )
    .then((res) => {
      return checkRes<RoomWithClicks, null>(res, null);
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
  return metadata;
};

export const createRoom = async (
  Name: string,
  EventDate: string,
  accessToken: string
): Promise<void> => {
  const room = {
    Name,
    EventDate,
    Done: false,
  };
  await fetch(
    API + "/api/rooms",
    getOptions(accessToken, "POST", JSON.stringify(room))
  );
};

export const deleteRoom = async (
  id: string,
  accessToken: string
): Promise<void> => {
  await fetch(API + "/api/rooms/" + id, getOptions(accessToken, "DELETE"));
};

export const editRoom = async (
  room: Room,
  accessToken: string
): Promise<void> => {
  await fetch(
    API + "/api/rooms/" + room.Id,
    getOptions(accessToken, "PUT", JSON.stringify(room))
  );
};

export const sendClickToServer = async (roomId: string): Promise<void> => {
  const singelton = await getInstance();
  await singelton.invoke("Clicked", roomId);
};
