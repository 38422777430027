import { getEnvironment } from "./environmentResolver";

export const getServerAPIEndpoint = (): string => {
  const environment = getEnvironment();
  switch (environment) {
    case "local":
      return "https://localhost:7114";
    case "staging":
      return "https://prs-cdp-dev-clicker-webapp.azurewebsites.net";
    case "production":
      return "https://prs-cdp-prod-clicker-webapp.azurewebsites.net";
    default:
      throw new Error("Unknown environment: " + environment);
  }
};
