import { getEnvironment } from "./environmentResolver";

const authority =
  "https://login.microsoftonline.com/8ec14266-0c54-4c3f-a235-12a656cf0bdb"; // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})

const cache = {
  cacheLocation: "sessionStorage", // This configures where your cache will be stored
  storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
};

const localClientId = "e9a723d4-d571-407f-9185-8e24347d58fd";
const stagingClientId = "e9a723d4-d571-407f-9185-8e24347d58fd";
const productionClientId = "15404bcf-7933-42bb-ba92-e898741c5741";

const localBackendScopesId =
  "api://1b105cd7-18d3-4897-9f87-cf41d148f08e/access_as_user";
const stagingBackendScopesId =
  "api://1b105cd7-18d3-4897-9f87-cf41d148f08e/access_as_user";
const productionBackendScopesId =
  "api://prs-cdp-clicker-application-backend/access_as_user";

const msalConfigLocal = {
  auth: {
    clientId: localClientId,
    authority,
    redirectUri: "http://localhost:3000",
  },
  cache,
};

const msalConfigStaging = {
  auth: {
    clientId: stagingClientId,
    authority,
    redirectUri: "https://dev.clicker.prs.se",
  },
  cache,
};

const msalConfigProduction = {
  auth: {
    clientId: productionClientId,
    authority,
    redirectUri: "https://clicker.prs.se",
  },
  cache,
};

export const getMsalConfig = () => {
  const environment = getEnvironment();
  switch (environment) {
    case "local":
      return msalConfigLocal;
    case "staging":
      return msalConfigStaging;
    case "production":
      return msalConfigProduction;
    default:
      throw new Error("Unknown environment: " + environment);
  }
};

const tokenRequestLocal = {
  scopes: [localBackendScopesId],
};

const tokenRequestStaging = {
  scopes: [stagingBackendScopesId],
};

const tokenRequestProduction = {
  scopes: [productionBackendScopesId],
};

export const getTokenRequest = () => {
  const environment = getEnvironment();
  switch (environment) {
    case "local":
      return tokenRequestLocal;
    case "staging":
      return tokenRequestStaging;
    case "production":
      return tokenRequestProduction;
    default:
      throw new Error("Unknown environment: " + environment);
  }
};

export const loginRequest = {
  scopes: ["User.Read"],
};
