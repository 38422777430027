type Environment = "local" | "staging" | "production";

export const getEnvironment = (): Environment => {
  const hostname = window.location.hostname;

  if (hostname.indexOf("localhost") > -1 && !isBuildVersion()) {
    return "local";
  } else if (hostname.indexOf("dev.clicker.prs.se") > -1 && isBuildVersion()) {
    return "staging";
  } else {
    return "production";
  }
};

export const isBuildVersion = (): boolean => {
  return process.env.NODE_ENV === "production";
};
